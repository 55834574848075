import stephanSanta from "./img/stephanSanta2.jpg";
import stephanSantaOverlay from "./img/stephanSanta2overlay.png";
import "./App.css";
import { useState } from "react";

function App() {
  const [showAnyway, setShowAnyway] = useState(false);
  const christmas = [];
  const getRandomInt = (max) => {
    return Math.floor(Math.random() * Math.floor(max));
  };

  const addEmojies = (count, emojie, isTurning) => {
    for (let i = 0; i < count; i++) {
      //different when
      const x = getRandomInt(750);
      const y = getRandomInt(350);
      christmas.push(
        <div
          style={{
            left: y + "px",
            top: x + "px",
            position: "absolute",
            width: 10 + "px",
            height: 10 + "px",
          }}
          className={isTurning ? "App-logo middle" : "middle"}
        >
          {emojie}
        </div>
      );
    }
  };
  console.log(window.innerWidth);

  addEmojies(50, "⭐", true);
  addEmojies(50, "🎄", false);

  return (
    <div className="App">
      <h1 className="">Scheidegger Farm</h1>
      <p>Diese Webseite ist noch in bearbeitung</p>
    </div>
  );
}

export default App;
